(function () {'use strict';}());
angular.module('app').service('AppService', function($http)
{
	var trace = angular.trace;
	var lookup = {};
	var listing = [];

	function load()
	{

	}

	return {
		load: load
	};
});